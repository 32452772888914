import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1579 12C0.839475 12 0.56679 11.8931 0.339843 11.6793C0.112896 11.4655 -0.000384982 11.2087 9.82933e-07 10.9091V3.27273H1.1579V10.9091H7.52632V12H1.1579ZM3.47369 9.81818C3.15526 9.81818 2.88258 9.71127 2.65563 9.49746C2.42868 9.28364 2.3154 9.02691 2.31579 8.72727V1.09091C2.31579 0.79091 2.42926 0.534001 2.65621 0.320183C2.88316 0.106365 3.15565 -0.00036271 3.47369 9.26069e-07H7.52632L11 3.27273V8.72727C11 9.02727 10.8865 9.28418 10.6596 9.498C10.4326 9.71182 10.1601 9.81855 9.8421 9.81818H3.47369ZM6.94737 3.81818H9.8421L6.94737 1.09091V3.81818Z"
        fill="#AAAAAA"
      />
    </Svg>
  );
};

export default Icon;
